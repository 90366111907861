<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Pdfviewer',
  props: {
    src: {
      type: String,
      required: true,
    },
    errorString: {
      type: String,
      default: 'This browser does not support PDFs. Download the PDF to view it:'
    },
  },
  data() {
    return {
      hashId: `pdfviewer-${Math.random().toString(36).substr(2, 9)}`,
    }
  },
  methods: {
    renderObject(h) {
      return h('object', {
        attrs: {
          id: this.hashId,
          data: this.src,
          type: 'application/pdf',
          width: '100%',
          height: '100%'
        },
      }, [
        this.renderIFrame(h)
      ]);
    },
    renderIFrame(h) {
      return h('iframe', {
        staticClass: 'pdfviewer_iframe',
        attrs: {
          src: this.src,
          width: '100%',
          height: '100%'
        }
      }, [
        this.renderText(h),
      ]);
    },
    renderText(h) {
      return h('p', 'This browser does not support PDFs. Download the PDF to view it:', [
        h('a', {
          attrs: {
            href: this.src,
            target: '_blank'
          },
        }),
      ]);
    },
  },
  render(h) {
    if (!!this.src && this.src.length > 0) {
      return h('div', {
        staticClass: 'pdfviewer',
      }, [
        this.renderObject(h),
      ])
    }
    return '';
  },
});
</script>
<style scoped>
  .pdfviewer {
    overflow: hidden;
    width: 100%;
  }

  .pdfviewer.__iframe {
    border: 0;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
</style>
